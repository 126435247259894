import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom';
import {constants} from "../config/constants";

export const CoachRoute = ({children}) => {
    document.title = `Coach Dashboard | ${constants.website_title}`
    const user = useSelector(state => state.auth);

    return user.is_coach === true
        ? children
        : <Navigate to="/" />
}
