import React, {useEffect} from 'react';
import { useSelector } from 'react-redux'
import {Navigate, useParams} from 'react-router-dom';
import {Loading} from "../components/ui/loading/Loading";
import {getOrganizationTeam} from "../store/user_organizations/teams/team";

export const FacilitatorDashboardRoute = ({children}:any) => {
    const router = useSelector((state:any) => state.router);
    const user_organizations_obj = useSelector((state:any) => state.user_organizations);

    if(router.selected_team_id == null || router.selected_organization_id == null) {
        return <Navigate to="/" />
    }

    let organization_id = router.selected_organization_id;
    let team_id = router.selected_team_id;

    if(user_organizations_obj.isLoading) {
        return (
            <>
                <div className="row loading-center-fixed">
                    <div className="col-12 text-center mb-4">
                        <img src={require('../assets/images/zynity_logo.png')} width="400" alt=""/>
                    </div>
                    <div className="col-12 text-center mt-4">
                        <h2>Loading...<span style={{ fontSize: '16px' }}><Loading /></span></h2>
                    </div>
                </div>
            </>
        )
    }

    const user_organizations = user_organizations_obj.data?.data
    let user_organization_selected = user_organizations.find((item:any) => item.id == organization_id)
    if(user_organization_selected == undefined) {
        return <Navigate to="/" />
    }

    let user_team_selected = user_organization_selected.teams.find((item:any) => item.id == team_id)

    if(user_team_selected == undefined) {
        return <Navigate to="/" />
    }

    return children
}
