import React from 'react'
import {Header} from "../../../header/Header";

export const NotifierApp = () => {
    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-4 d-flex justify-content-between align-items-center">
                    <div className="col-lg-8 offset-lg-2 col-12 text-center">
                        <h2 style={{fontFamily: 'Segoe UI",Arial,sans-serif', fontWeight: '400'}}>Zynity 'Team Updates' Notifier App</h2>
                        <div style={{width: '50%', height: '4px', backgroundColor: 'lightgreen', marginLeft: 'auto', marginRight: 'auto'}} />
                    </div>
                    <div className="col-lg-8 offset-lg-2 col-12 mt-3">
                        <p>Zynity Notifier App, also known as Zynity Team Update, is designed to provide real-time notifications on your personal device for new items added by team members. It works seamlessly with the Collab App (Executive Team), and the Highlights App.</p>

                        <p>The Team Updates app is available for <b><a href="https://www.microsoft.com/store/productId/9NHDZRJ05NP3?ocid=pdpshare" target='_blank'>Windows</a>, <a
                            href="https://apps.apple.com/mx/app/zynity-team-updates/id1567160071" target='_blank'>MacOS</a>, <a
                            href="https://play.google.com/store/apps/details?id=com.zynity.z_team_updates" target='_blank'>Android</a>,</b> and <b><a
                            href="https://apps.apple.com/mx/app/zynity-team-updates/id1567160071" target='_blank'>iPhone</a>.</b>
                        </p>

                        <p>To download the app, select your operating system below and follow the instructions. Once installed, log in using your Zynity credentials and stay informed about member activity.</p>
                        <p><i>Note: If updating to a newer version, remember to uninstall the previous version first.</i></p>

                        <div style={{background: 'lightgray', padding: '4px'}}>
                            <b>Windows</b>
                        </div>
                        <ol className='mx-4 mt-2'>
                            <li>Access the Microsoft Store using your device.</li>
                            <li>Search for "zynity" and click to install when ‘Zynity Team Updates’ shows in the list.</li>
                            <li>Follow the instructions/prompts.</li>
                            <li>Open the app and log in.</li>
                        </ol>

                        <div style={{background: 'lightgray', padding: '4px'}}>
                            <b>MacOS:</b>
                        </div>
                        <ol className='mx-4 mt-2'>
                            <li>Access the iCloud store using your device.</li>
                            <li>Search for "zynity" and click to install when ‘Zynity Team Updates’ shows in the list.</li>
                            <li>Follow the instructions/prompts.</li>
                            <li>Open the app and log in.</li>
                        </ol>

                        <div style={{background: 'lightgray', padding: '4px'}}>
                            <b>Android:</b>
                        </div>
                        <ol className='mx-4 mt-2'>
                            <li>Access the Android Play store using your mobile device.</li>
                            <li>Search for "zynity" and click to install when ‘Zynity Team Updates’ shows in the list.</li>
                            <li>Follow the instructions/prompts.</li>
                            <li>Open the app and log in.</li>
                        </ol>

                        <div style={{background: 'lightgray', padding: '4px'}}>
                            <b>iPhone:</b>
                        </div>
                        <ol className='mx-4 mt-2 mb-5'>
                            <li>Access the iPhone App store using your mobile device.</li>
                            <li>Search for "zynity" and click to install when ‘Zynity Team Updates’ shows in the list.</li>
                            <li>Follow the instructions/prompts.</li>
                            <li>Open the app and log in.</li>
                        </ol>
                    </div>
                </div>
            </div>
        </>
    )
}
