import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom';

export const AdminRoute = ({children}) => {
    const user = useSelector(state => state.auth);

    return user.visibility_profile === 'Zadmin'
        ? children
        : <Navigate to="/" />
}
