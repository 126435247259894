import React, {useState} from 'react'
import {Header} from "../../../../header/Header";
import {FacilitatorHeader} from "../FacilitatorHeader";
import {useDispatch, useSelector} from "react-redux";
import {Loading} from "../../../../ui/loading/Loading";
import DataTable from "react-data-table-component";
import {MenuCell} from "./MenuCell";
import axios from "axios";
import Swal from "sweetalert2";
import {zynity_api} from "../../../../../config/external-routes";
import {getOrganizationTeam} from "../../../../../store/user_organizations/teams/team";
import {Button, Form, Modal} from "react-bootstrap";
import ReactQuill from "react-quill";
import {constants} from "../../../../../config/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

export const ManageMembers = () => {
    const dispatch = useDispatch();
    const router = useSelector((state:any) => state.router);
    let organization_id = router.selected_organization_id;
    let team_id = router.selected_team_id;
    const organization_team = useSelector((state:any) => state.organization_team);
    const [showChangeRoleModal, setShowChangeRoleModal] = useState(false);
    const [selectedNewMemberRole, setSelectedNewMemberRole] = useState('');
    const [teamPeopleIdToChange, setTeamPeopleIdToChange] = useState('');

    if(organization_team.isLoading) {
        return (
            <>
                <Header />
                <Loading />
            </>
        )
    }

    const members = organization_team.data.members.concat(organization_team.data.viewers)

    members.sort((a: { id: number; }, b: { id: number; }) => a.id - b.id);

    console.log("members", members)

    const getMemberRole = (row:any) => {
        if(row.isFacilitator != undefined && row.isFacilitator.toLowerCase() == 'yes' && row.isVisitor != undefined && row.isVisitor.toLowerCase() == 'yes') {
            return 'Facilitator'
        } else if(row.isFacilitator != undefined && row.isFacilitator.toLowerCase() == 'yes') {
            return 'Facilitator-Member'
        } else if(row.isVisitor != undefined && row.isVisitor.toLowerCase() == 'yes') {
            return 'Follower'
        } else {
            return 'Member'
        }
    }

    const handleChangeMemberRoleModal = (row:any) => {
        setTeamPeopleIdToChange(row.team_people_id)
        setShowChangeRoleModal(true)
    }

    const handleRemoveMember = (row:any) => {
        console.log("to remove to team_people_id: " + row.team_people_id)

        let url = zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id);
        let linkDelete = `${url}/facilitator_dashboard/remove_member?team_people_id=${row.team_people_id}`;

        Swal.fire({
            title: `Do you really want to remove to ${row.KnownAs} ?`,
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete',
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Member Deleted!', '', 'success')
                const response = axios.delete(linkDelete);
                response.then((success) => {
                    dispatch(getOrganizationTeam({organization_id , team_id}) );
                });
            }
        })
    }

    const columns = [
        {
            name: 'Member Id',
            selector: (row: { id: any; }) => row.id,
            maxWidth: '10px'
        },
        {
            name: 'Name',
            selector: (row: { FirstName: any; LastName:any; }) => `${row.FirstName} ${row.LastName}`,
            maxWidth: '250px'
        },
        {
            name: 'Email',
            selector: (row: { EmailAddress: any; }) => row.EmailAddress,
            maxWidth: '250px'
        },
        {
            name: 'Role',
            selector: (row: { EmailAddress: any; }) => getMemberRole(row),
            maxWidth: '250px'
        },
        {
            name: 'Menu',
            cell: (row: any) => <MenuCell row={row} handleChangeRoleModal={handleChangeMemberRoleModal} handleRemoveMember={handleRemoveMember} />,
            allowOverflow: true
        }
    ];

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedNewMemberRole(event.target.value);
    };

    const handleSubmitChangeRoleMember = () => {
        let url = zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id);

        let data = {
            team_people_id: teamPeopleIdToChange,
            new_role: selectedNewMemberRole,
        }

        axios.put(`${url}/facilitator_dashboard/change_member_role`, data)
            .then((response) => {
                dispatch(getOrganizationTeam({organization_id , team_id}) )
                setShowChangeRoleModal(false)
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: response.data.message,
                })
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'We have problems updatingthe role!',
                })
            })
    }

    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-2 mb-5">
                    <div className="col-12 col-md-9">
                        <div className="row">
                            <FacilitatorHeader page_name={'Manage Members'} />
                        </div>
                    </div>
                    <div className="col-md-3 col-12 mt-3">
                        <Link to="add-member" className='btn btn-teal'>Add Member</Link>
                    </div>
                    <div className="col-12 mt-3">
                        <DataTable
                            columns={columns}
                            data={members}
                        />
                    </div>
                </div>
            </div>

            <Modal show={showChangeRoleModal}>
                <Form>
                    <Modal.Header closeButton className='bg-teal'>
                        <Modal.Title>Select Member Role</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='row d-flex align-items-center'>
                        <div className="col-12">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault1"
                                    value="regular"
                                    checked={selectedNewMemberRole === 'regular'}
                                    onChange={handleOptionChange}
                                />
                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    Member <span className="text-muted">- Full Access</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault2"
                                    value="follower"
                                    checked={selectedNewMemberRole === 'follower'}
                                    onChange={handleOptionChange}
                                />
                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                    Follower <span className="text-muted">- Can view and comment</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault3"
                                    value="facilitator"
                                    checked={selectedNewMemberRole === 'facilitator'}
                                    onChange={handleOptionChange}
                                />
                                <label className="form-check-label" htmlFor="flexRadioDefault3">
                                    Facilitator <span className="text-muted">- Team oversight</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault4"
                                    value="facilitator-member"
                                    checked={selectedNewMemberRole === 'facilitator-member'}
                                    onChange={handleOptionChange}
                                />
                                <label className="form-check-label" htmlFor="flexRadioDefault4">
                                    Facilitator-Member <span className="text-muted">- Team oversight - Active participant</span>
                                </label>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowChangeRoleModal(false)}>
                            Cancel
                        </Button>
                        <Button className='btn-teal' onClick={handleSubmitChangeRoleMember}>
                            Continue
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}
