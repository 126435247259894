import React from 'react'
import { useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
// @ts-ignore
import Cookies from 'js-cookie'

import { UserHome } from '../components/user_home/UserHome'
import { constants } from '../config/constants'
import {zynity_api, zynity_website} from '../config/external-routes'
import { Loading } from '../components/ui/loading/Loading'
import { UserProfile } from '../components/user/profile/UserProfile'
import { ZAdmin } from '../components/admin/z_admin/ZAdmin'
import { AdminRoute } from './AdminRoute'
import { UsersManage } from '../components/admin/users/user_manage/UsersManage'
import {ServicesManage} from "../components/admin/services/ServicesManage";
import {ServiceDetails} from "../components/admin/services/details/ServiceDetails";
import {OrganizationsManage} from "../components/admin/organizations/OrganizationsManage";
import {OrganizationDetails} from "../components/admin/organizations/details/OrganizationDetails";
import {CoachesManage} from "../components/admin/coaches/CoachesManage";
import {CoachDetails} from "../components/admin/coaches/details/CoachDetails";
import {ContentEditor} from "../components/admin/content_editor/ContentEditor";
import {CoachRoute} from "./CoachRoute";
import {CoachDashboard} from "../components/coaches/CoachDashboard";
import {CoachProfile} from "../components/coaches/profile/CoachProfile";
import {CoachAgreement} from "../components/coaches/CoachAgreement";
import {Assessment} from "../components/leadership_assessment/Assessment";
import {TeamAssessment} from "../components/leadership_assessment/TeamAssessment";
import {TodayAssessment} from "../components/leadership_assessment/TodayAssessment";
import {TomorrowAssessment} from "../components/leadership_assessment/TomorrowAssessment";
import {HowLead} from "../components/user/menu/how_to_lead/HowLead";
import {NotifierApp} from "../components/user/menu/notifier_app/NotifierApp";
import {AdminHome} from "../components/organizations/admin/AdminHome";
import {OrganizationRoute} from "./OrganizationRoute";
import {OrganizationProfile} from "../components/organizations/admin/profile/OrganizationProfile";
import {ManageMembers} from "../components/organizations/admin/members/ManageMembers";
import {ManageTeams} from "../components/organizations/admin/teams/ManageTeams";
import {ManageCoaches} from "../components/organizations/admin/coaches/ManageCoaches";
import {ManageAdministrators} from "../components/organizations/admin/administrators/ManageAdministrators";
import {RemoveOrganization} from "../components/organizations/admin/remove_organization/RemoveOrganization";
import {OrganizationTeamRoute} from "./OrganizationTeamRoute";
import {CoachNotes} from "../components/organizations/teams/coach_notes/CoachNotes";
import {setLogOut, setUser, startLoading} from "../store/auth";
import {getUserOrganizations} from "../store/user_organizations";
import axios from "axios";
import {RedirectRouter} from "../helpers/RedirectRouter";
import {CnA} from "../components/organizations/teams/CnA/CnA";
import {FacilitatorDashboardRoute} from "./FacilitatorDashboardRoute";
import {Dashboard} from "../components/organizations/teams/facilitator_dashboard/Dashboard";
import {TeamProfile} from "../components/organizations/teams/facilitator_dashboard/profile/TeamProfile";
import { ManageMembers as FacilitatorDashboardManageMembers  } from "../components/organizations/teams/facilitator_dashboard/members/ManageMembers";
import { ManageCoaches as FacilitatorDashboardManageCoaches  } from "../components/organizations/teams/facilitator_dashboard/coaches/ManageCoaches";
import {ManageTools} from "../components/organizations/teams/facilitator_dashboard/tools/ManageTools";
import {RemoveTeam} from "../components/organizations/teams/facilitator_dashboard/remove/RemoveTeam";
import {
    SuiteSelection
} from "../components/organizations/teams/facilitator_dashboard/tools/suite_selection/SuiteSelection";
import {getServices} from "../store/global/services";
import {AddMember} from "../components/organizations/teams/facilitator_dashboard/members/AddMember";
import {RedirectBase64} from "../helpers/RedirectBase64";

export const AppRouter = () => {

    const dispatch = useDispatch();
    const user = useSelector((state:any) => state.auth)
    useEffect(() => {

        if(process.env.REACT_APP_ENV === constants.envs.local) {
            Cookies.set(constants.zynity_api_access_token_cookie_name, process.env.REACT_APP_ZYNITY_API_TOKEN_DEV_TOKEN);
        }

        let access_token = Cookies.get(constants.zynity_api_access_token_cookie_name);
        if (access_token === undefined) {
            window.location.replace(zynity_website.yii_website_logout_url);
        }

        dispatch(startLoading())

        axios.defaults.headers.common = {'Authorization': `Bearer ${access_token}`}
        axios.get(zynity_api.retrieve_authenticated_user)
            .then((response) => {
                response.data.user.access_token = access_token;
                dispatch(setUser(response.data.user))
                dispatch(getUserOrganizations());
                dispatch(getServices());
            }).catch(() => {
            dispatch(setLogOut())
        })
    }, [dispatch])

    if(user.redirectToLoginPage === true) {
        window.location.replace(zynity_website.yii_website_logout_url);
    }

    if (user.isLoading) {
        return (
            <>
                <div className="row loading-center-fixed">
                    <div className="col-12 text-center mb-4">
                        <img src={require('../assets/images/zynity_logo.png')} width="400" alt=""/>
                    </div>
                    <div className="col-12 text-center mt-4">
                        <h2>Loading...<span style={{ fontSize: '16px' }}><Loading /></span></h2>
                    </div>
                </div>
            </>
        )
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<UserHome />} />
                <Route path="/user/profile" element={<UserProfile />} />
                <Route path="/admin/*" element={
                    <AdminRoute>
                        <Routes>
                            <Route path="/z-admin" element={<ZAdmin />} />
                            <Route path="/people" element={<UsersManage />} />
                            <Route path="/people/:user_id" element={<UserProfile external_user={true} read_only={true} />} />
                            <Route path="/people-update/:user_id" element={<UserProfile external_user={true} />} />

                            <Route path="/services" element={<ServicesManage />} />
                            <Route path="/services/:service_id" element={<ServiceDetails />} />
                            <Route path="/services-update/:service_id/" element={<ServiceDetails readOnly={false} />} />

                            <Route path="/organizations" element={<OrganizationsManage />} />
                            <Route path="/organizations/:organization_id" element={<OrganizationDetails />} />
                            <Route path="/organizations-update/:organization_id/" element={<OrganizationDetails readOnly={false} />} />

                            <Route path="/coaches" element={<CoachesManage />} />
                            <Route path="/coaches/:coach_id" element={<CoachDetails />} />
                            <Route path="/coaches-update/:coach_id/" element={<CoachDetails readOnly={false} />} />

                            <Route path="/content-editor/:page" element={<ContentEditor />} />
                        </Routes>
                    </AdminRoute>
                } />

                <Route path="/coach/*" element={
                    <CoachRoute>
                        <Routes>
                            <Route path="/" element={<CoachDashboard />} />
                            <Route path="/certified-coach-profile-update" element={<CoachProfile />} />
                            <Route path="/agreement" element={<CoachAgreement />} />
                        </Routes>
                    </CoachRoute>
                } />

                <Route path="/leadership-assessment/*" element={
                    <Routes>
                        <Route path="/" element={<Assessment />} />
                        <Route path="/team" element={<TeamAssessment />} />
                        <Route path="/today" element={<TodayAssessment />} />
                        <Route path="/tomorrow" element={<TomorrowAssessment />} />
                    </Routes>
                } />

                <Route path="/how-lead" element={<HowLead />} />
                <Route path="/notifier-app" element={<NotifierApp />} />

                <Route path="/organizations/:organization_id/*" element={
                    <OrganizationRoute>
                        <Routes>
                            <Route path="/" element={<AdminHome />} />
                            <Route path="/profile" element={<OrganizationProfile />} />
                            <Route path="/members" element={<ManageMembers />} />
                            <Route path="/teams" element={<ManageTeams />} />
                            <Route path="/coaches" element={<ManageCoaches />} />
                            <Route path="/coaches/:coach_id" element={<CoachProfile show_logged_coach={false} />} />
                            <Route path="/administrators" element={<ManageAdministrators />} />
                            <Route path="/remove" element={<RemoveOrganization />} />

                            <Route path="/teams/:team_id/*" element={
                                <OrganizationTeamRoute>
                                    <Routes>
                                        <Route path="/coach/notes" element={<CoachNotes />} />
                                        <Route path="/CnA" element={<CnA/>} />
                                        <Route path="/collab" element={<CnA/>} />
                                        <Route path="/CnA/:datum_id" element={<CnA/>} />
                                        <Route path="/collab/:datum_id" element={<CnA/>} />
                                    </Routes>
                                </OrganizationTeamRoute>
                            } />
                        </Routes>
                    </OrganizationRoute>
                } />

                <Route path="/facilitator-dashboard/*" element={
                    <FacilitatorDashboardRoute>
                        <Routes>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/team-profile" element={<TeamProfile />} />
                            <Route path="/manage-members" element={<FacilitatorDashboardManageMembers />} />
                                <Route path="/manage-members/add-member" element={<AddMember />} />
                            <Route path="/manage-tools" element={<ManageTools />} />
                                <Route path="/manage-tools/suite-selection" element={<SuiteSelection />} />
                            <Route path="/manage-coaches" element={<FacilitatorDashboardManageCoaches />} />
                            <Route path="/remove-team" element={<RemoveTeam />} />
                        </Routes>
                    </FacilitatorDashboardRoute>
                } />

            {/*    Slug Routes   */}
                <Route path="/coach-notes" element={<RedirectRouter />} />
                <Route path="/collab" element={<RedirectBase64 />} />
            </Routes>
        </BrowserRouter>
    )
}
