import React, {useEffect, useState} from 'react'
import Nav from "react-bootstrap/Nav";
import {HeadlineModal} from "../../../ui/tools/HeadlineModal";
import {ReminderModal} from "../../../ui/tools/ReminderModal";
import {CnATeamCard} from "./panels/team/CnATeamCard";
import {useDispatch, useSelector} from "react-redux";
import {Loading} from "../../../ui/loading/Loading";
import {zynity_api} from "../../../../config/external-routes";
import {CnAHeader} from "./cna_header/CnAHeader";
import {TeamPanel} from "./panels/team/TeamPanel";
import {MemberPanel} from "./panels/member/MemberPanel";
import {useNavigate, useParams} from "react-router-dom";
import {findDatumByDatumId, findDatumByDatumIdInAllTools} from "../../../../helpers/tools";
import {
    collabSetPanelViewRemember,
    collabSetRemember,
    setDatumIdOpen,
    setDatumIdOpenData
} from "../../../../store/user_organizations/teams/tools/connect_and_align";

export const CnAContainer = () => {
    const dispatch = useDispatch();
    const {organization_id, team_id} = useParams();
    const navigate = useNavigate();
    const cna = useSelector((state:any) => state.tool_cna)
    const [selectedMember, setSelectedMember] = useState('team');
    const tool_cna_remember = useSelector((state: any) => state.tool_cna_remember);
    let cna_data = cna.data;

    console.log("cna.datum_id_open", cna.datum_id_open)

    useEffect(() => {
        if(cna?.datum_id_open != undefined && cna.datum_id_open > 0) {
            let selected_datum = findDatumByDatumIdInAllTools(cna_data, parseInt(cna.datum_id_open))
            console.log("Going to update from datum_id", cna.datum_id_open, selected_datum)
            if(selected_datum !== undefined) {
                dispatch( setDatumIdOpenData(selected_datum) );
                setSelectedMember(selected_datum.user_id)
            }
        }
    }, [cna.isLoading, cna.datum_id_open])

    useEffect(() => {
        if(cna.isLoading == false) {
            let remember_data = tool_cna_remember?.remember
            if(remember_data != null && remember_data.length > 0) {
                let organizationData = remember_data.find((org: { organization_id: number; team_id:number; view:'team'|'member'; open: boolean }) => (
                    org.organization_id === parseInt(organization_id) && org.team_id === parseInt(team_id) && org.open === true
                ));

                if(organizationData != null) {
                    if(organizationData.view == 'member') {
                        if(organizationData?.users != undefined && organizationData.users.length > 0) {
                            console.log(" organizationData.users",  organizationData.users)
                            let org_user = organizationData.users[0].id
                            setSelectedMember(org_user)
                        }

                    } else if(organizationData.view == 'team') {
                        setSelectedMember('team')
                    }
                }
            }
        }
    }, [cna.isLoading])

    if(cna.isLoading) {
        return <Loading />
    }

    if(selectedMember === 'coach_notes') {
        navigate(`/organizations/${organization_id}/teams/${team_id}/coach/notes`)
    }

    if(selectedMember != 'team' && cna.isLoadingFullData) {
        return <Loading />
    }

    const changePanelViewHandle = (panel:any) => {
        setSelectedMember(panel)

        if(panel == 'coach_notes')
            return true;

        let rememberData:any = {
            organization_id: parseInt(organization_id),
            team_id: parseInt(team_id),
            view: panel == 'team' ? 'team' : 'member'
        }

        if(panel != 'team') {
            rememberData.user_id = parseInt(panel)
        }

        dispatch(collabSetPanelViewRemember(rememberData))
    }


    return (
        <div className='container'>
            <CnAHeader setSelectedMember={changePanelViewHandle} selectedMember={selectedMember} />
            {
                selectedMember === 'team' && <TeamPanel setSelectedMember={changePanelViewHandle} />
            }
            {
                !isNaN(+selectedMember) && <MemberPanel selectedMember={selectedMember} />
            }
        </div>
    )
}
